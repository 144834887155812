(function (gsr, $) {

  const icons = `<span>
          Icons made by
          <a href="https://www.flaticon.com/authors/nhor-phai" title="Nhor Phai">Nhor Phai</a>
          from
          <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
          licensed by
          <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a>
        </span>`;

  $(document).ready(function () {

    initIcons();
    setTimeout(() => {
      document.getElementById('icon-license').innerHTML = icons;
    }, 2000);
  });


  function initIcons() {
    $.ajax('/img/icons/sprite.svg', {
      mimeType: 'image/svg+xml',
      options: {responseType: 'document'},
      success: (data) => {
        const sprite = document.body.insertBefore(data.documentElement, document.body.firstChild);
        sprite.setAttribute('class', 'sprite');
        replaceIcons(document);
        // initObserver();
      }
    })
  }

  function replaceIcons(doc) {
    doc.querySelectorAll('.svg').forEach(icon => {
      const iconName = icon.className.match(/svg--([A-z\-_0-9]*)/)[1];
      icon.innerHTML = `<svg aria-hidden="true" focusable="false"><use xlink:href="#${iconName}"></use></svg>`
    });
  }
})(window.gsr = window.gsr || {}, jQuery);
